import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";
import breakpoints from "../styles/breakpoints";
import Container from "./Core/Container";
import Section from "./Core/Section";

// icons
import Icon from "./Core/Icon";
import emptyStar from "../assets/icons/empty-star.svg";
import star from "../assets/icons/star.svg";


const Styles = styled.div`
    
`;

const CardList = styled.ul`
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1.25rem;
    padding: 0;
    margin: 0;

    @media only screen and (min-width: ${breakpoints.md}) {
        grid-template-columns: 1fr 1fr;
    }

    @media only screen and (min-width: ${breakpoints.desktop}) {
        grid-template-columns: ${props => props.property ? "1fr 1fr" : "1fr 1fr 1fr"};
    }

`;

const Card = styled.li`
    min-height: 15.75rem;
    width: 100%;
    box-shadow: 0px 3px 0.2rem 0px rgb(0 0 0 / 30%);
    border-radius: .25rem;
    position: relative;

    .gatsby-image-wrapper {
        height: 23.75rem;
        width: 100%;
    }

    .property-container {
      padding: 1.25rem;
    }

    h3 {
        font-size: 1.5rem;
        margin: 0;
    }

    p {
        margin-bottom: 1.25rem;
    }
    
    .region {
        max-width: 17.188rem;
    }

    .link {
        border: none;
        background: var(--secondary);
        color: var(--white);
        cursor: pointer;
        box-sizing: border-box;
        padding: 15px 45px;
        text-align: center;
        font-weight: 600;
        border-radius: 5px;
        display: block;
        font-size: 0.875rem;
        margin: 0 auto;
        text-decoration: none;
        position: absolute;
        bottom: 1.25rem; 
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        z-index: 3;

        &:hover,
        &:focus {
            background: var(--lightenButton);
        }
    }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-decoration: none;
  box-sizing: border-box;
  background: #fff;
  position: relative;
  font-size: 1.5rem;
  color: #000;

  .margin,
  .price {
    margin-bottom: 4.375rem;
  }
  
  .overlay-link {
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    cursor: pointer;
  }
`;

const IconCard = styled.div`
    span {
       color: var(--secondary); 
    }

    .price {

        font-size: 1.15rem;

        span {
            color: #000;
            font-weight: 700;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 1.25rem;
        display: flex;
    }

  li {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 1.25rem;
      z-index: 3;
  }

  .icon {
      height: 1.5rem;
      width: 1.5rem;
      padding: 0.5rem;
      background: var(--primary);
      fill: var(--white);
      border-radius: 50%;
  }
`;

const Large = styled.div`
    span {
        font-weight: 700;
    }

    p {
        margin-bottom: 0;
    }

    p:first-of-type {
        margin-bottom: 1.25rem;
    }

    ul {
        margin-top: 1.25rem;
        padding: 0;
        font-size: 1rem;
        list-style: none; 

        p.amenties {
            font-weight: 700;
            margin-bottom: 0;
        }
    }

    li {
        display: inline-block;
        margin-right: .25rem;
    }
`;

const StarContainer = styled.div`
    p {
        text-decoration: underline;
        margin-bottom: 1.25rem;
    }
`;

const Star = styled.img`
    display: inline-block;
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 2px;
`;

const Tooltip = styled.span`
    position: absolute;
    top: -1.7rem;
    z-index: 2;
    left: 50%;
    color: #fff!important;
    text-transform: capitalize;
    font-size: .75rem;
    background: rgba(0,0,0,0.8);
    width: max-content;
    padding: 0.5rem;
    border-radius: 0.4rem;
    opacity: 0;
    transition: opacity .5s ease;
    transform: translateX(-50%);

    .icons:hover > & {
        opacity: 1;
    }

`;


const PropertyList = (props) => {

    const {properties} = props;
    const externalProperties = props.cards;

    function sortAmenities(arr) {

        const sortedArray=[];
            
        arr.forEach(amenity => {

            let name;
                let index;
                let icon;

            switch(true) {
                case /hot-tub|hottub|hot tub/gi.test(amenity):
                    name = "hot tub";
                    icon = "icon-hot-tub";
                    index = 1;
                    break;
                case /dog |pet|dogs/gi.test(amenity):
                    name = "pet friendly";
                    icon = "icon-pet";
                    index = 2;
                    break;
                case /wifi|wi-fi|broadband/gi.test(amenity):
                    name = "wi-fi";
                    icon = "icon-thunderbolt";
                    index = 3;
                    break;
                case /garden|secluded/gi.test(amenity):
                    name = "garden";
                    icon = "icon-flower";
                    index = 4;
                    break;
                case /parking/gi.test(amenity):
                    name = "parking";
                    icon = "icon-parking";
                    index = 5;
                    break;
                default:
                    name = amenity;
                    icon = "icon-star";
                    index = 6;
            }

            sortedArray.push({
                "name":name, 
                "index":index, 
                "icon":icon, 
            });
            
        });

       return sortedArray.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
    }

    return (
      <Section id={props.id ? props.id : null}>
        <Container>
          <Row>
            <Col lg={props.property ? 9 : 12}>
              <Styles>
                {props.heading ? <h2>{props.heading}</h2> : ""}
                {props.tagline ?<p>{props.tagline}</p>: ""}
                <CardList property={props.property}>
                  {properties.map(function(property, index){
                        
                        let sortedArray;
                        // sort amentities into popularity brackets
                        if (props.cardType === "icon" && property.amenities.length > 0) {
                            sortedArray = sortAmenities(property.amenities);
                            sortedArray = sortedArray.slice(0, 4);
                        }

                        const price = "Enquire for price";
                            let isPriceNum;
                        
                        // comenting prices out, as for V1 we just want price to say enquire
                        // if (isNaN(property.price) || property.price <= 0 || property.price >= 10000) {
                        //     isPriceNum = false; 
                        //     price = "Enquire for price";
                        // } else {
                        //     isPriceNum = true; 
                        //     price = String(property.price).includes('.') ? String(property.price).split('.')[0] : property.price;
                        // }

                        return ( 
                          <Card key={index} className="grid-item">
                            <CardContainer>
                              <Link to={property.slug.current} className="overlay-link" />
                              <Img fluid={property.image.image.asset.fluid} alt={property.image.alt} />
                              { props.cardType === "large" ? (
                                <Large className="property-container">
                                  <h3>{property.name}</h3>
                                  <p className="region">
                                    <span>Region: </span>
                                    {property.region}
                                  </p>
                                  {property.stars ? (
                                    <StarContainer>
                                      {[...Array(5)].map((x, i) =>
                                        <Star src={property.stars <= i ? emptyStar : star} key={i} />
                                                    )}
                                      {property.numReviews && (
                                      <p>
                                        Based on {property.numReviews} review{property.numReviews > 1 ? 's':''}.
                                      </p>
)}
                                    </StarContainer>
                                              ) 
                                            : "" }
                                  {property.beds && (
                                  <p>
                                    <span>Bedrooms: </span>
                                    {property.beds}
                                  </p>
) }
                                  {property.duration && (
                                  <p>
                                    <span>Duration: </span>
                                    {property.duration}
                                    {" "}
                                    nights
                                  </p>
) }
                                  {property.sleeps && (
                                  <p>
                                    <span>Sleeps: </span>
                                    {property.sleeps}
                                  </p>
) }
                                  {property.pets && (
                                  <p>
                                    <span>Pets: </span>
                                    {property.pets}
                                  </p>
) }
                                            
                                  <ul className="margin">
                                    {property.amenities.length > 0 && <p className="amenties">Amenties:</p> }
                                    {property.amenities.length > 0 ? property.amenities.map(function(amenity, index){
                                                    return ( 
                                                      <li key={index}>
                                                        {amenity}
                                                        {index + 1 === property.amenities.length ? "":","}
                                                      </li>
                                                    );
                                                }): ""}
                                  </ul>
                                  <Link to={property.slug.current} className="link">View property</Link>
                                            
                                </Large>
                                      )
                                        
                                        : props.cardType === "icon" ? (
                                          <IconCard className="property-container">
                                            <h3>{property.name}</h3>
                                            <p>
                                              {property.region}
                                              {property.sleeps ? " / ": ""}
                                              {property.sleeps && (
                                              <span>
                                                Sleeps &nbsp;
                                                {property.sleeps}
                                              </span>
)}
                                            </p>
                                            <ul>
                                              {sortedArray ? sortedArray.map(function(amenity, index){
                                                
                                                return ( 
                                                  <li className="icons" key={index} title={amenity.name}>
                                                    <Tooltip>{amenity.name}</Tooltip>
                                                    <Icon name={amenity.icon} />
                                                  </li>
                                                );
                                            }) : ""}
                                            </ul>
                                            <p>{property.description}</p>
                                            {/* {isPriceNum && <p className="price">Book from <span>£{price}</span> per night</p>} */}
                                            <p className="price">{price}</p>
                                            <Link to={property.slug.current} className="link">View property</Link>
                                          </IconCard>
                                      ) : (
                                        <div className="property-container">
                                          <h3>{property.name}</h3>
                                          <p className="region">{property.region}</p>
                                          <p className="margin">{property.description}</p>
                                          <Link to={property.slug.current} className="link">View property</Link>
                                        </div>
                                      )}
                            </CardContainer>
                          </Card>
                        );
                    })}
                  { externalProperties.length > 0 ? externalProperties.map(function(property, index){
                        const url = property.url.includes("utm") ? property.url : `${property.url  }?utm_source=hottubhideaways&utm_medium=referral&utm_campaign=lodge`;

                        return ( 
                          <Card key={index} className="grid-item">
                            <CardContainer>
                              <a href={url} target="_blank" rel="noreferrer" className="overlay-link" />
                              <Img fluid={property.image.image.asset.fluid} alt={property.image.alt} />
                              <div className="property-container">
                                <h3>{property.name}</h3>
                                <p className="region">{property.region}</p>
                                <p className="margin">{property.description}</p>
                                <a href={url} target="_blank" rel="noreferrer" className="link">View property</a>
                              </div> 
                            </CardContainer>
                          </Card>
                        );
                    }) : null}
                </CardList>
              </Styles>
            </Col>
          </Row>
        </Container>
      </Section>
    );
};

export default PropertyList;