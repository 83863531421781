import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Section from "./Core/Section";
import Container from "./Core/Container";

const Styles = styled.div`

    .blog-list {
        margin-top: 3.125rem;
        padding: 0 1.25rem;
    }

    .titles {
        margin: 0 0 1.25rem;
    }

    .grid-container {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-gap: 1.25rem;
        padding: 0;
    }

    .grid-item {
        background-size: cover;
        background-repeat: no-repeat;
        height: 15.75rem;
        width: 100%;
        box-shadow: 0 0 1.25rem rgb(0 0 0 / 30%);
        border-radius: .25rem;
        background-position: center;

        .bg-image {
                position: absolute!important;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: -1;
            }

        a {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            justify-content: flex-end;
            text-decoration: none;
            padding: 1.188rem;
            box-sizing: border-box;
            color: var(--white);
            background: rgba(0,0,0,0.4);
            position: relative;
            font-size: 1.5rem;
            

            p {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                text-align: center;
                font-weight: bold;
            }
        }
    }


    @media only screen and (min-width: 1024px) {

        .blog-list {
            padding: 0;
        }

        .grid-container {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .grid-item {
            height: 24.5rem;

            &:hover,
            &:focus-within {
                transform: scale(1.025);
                transform-origin: 50% 50%;
                transition: transform .3s ease-out;
            }
        }
    }
`;

class BlogList extends Component {


    state = {
           
    };

    render() {
        var blogposts = this.props.blogposts

        return (
            <Section id={ this.props.id ? this.props.id : null }>
                <Container>
                    <Styles>
                        { this.props.title || this.props.subtitle ? <div className="titles">
                            { this.props.title ? <h2 className="title">{this.props.title}</h2> : null }
                            { this.props.subtitle ? <p className="subtitle">{this.props.subtitle}</p> : null }
                        </div> : null }
                        <ul className="grid-container">
                            {blogposts.map(function(item, index){
                                return ( 
                                    <li key={ index } className="grid-item">
                                        <Link to={`${item.slug.current}`}>
                                            {item.image.image && <Img className="bg-image" fluid={item.image.image.asset.fluid} alt={item.image.alt}/>}
                                            <p>{item.title}</p>
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </Styles>
                </Container>
            </Section>
        )
    }
}

export default BlogList;