import React, { Component } from 'react';
import Container from "./Container";
import BlockContent from "@sanity/block-content-to-react";
import styled from "styled-components";
import {Row, Col} from "styled-bootstrap-grid";
import Section from "./Section"

const Styles = styled.div`

  a {
    display: inline;
    color: var(--primary);
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }

  }

  h1, h2, h3, h4, h5, h6, p, strong, b, li{
    &::first-letter {
      text-transform: uppercase;
    }
  }


`;

class Copy extends Component {

  constructor (params) {
      super(params)
      this.checkInRef = React.createRef()
  }

  componentDidMount () {
    let app = this;
  }

  render() {
    
    const serializers = {
      marks: {
        link: ({mark, children}) => {
          const { href } = mark
          return <a href={href} target="_blank" rel="noopener">{children}</a>
        },
        id: ({mark, children}) => {
          const { id } = mark
          return <span id={id}>{children}</span>
        },
      }
    }

    return (
      <>
      {this.props.property ?
      <Section>
        <Styles>
          <Container>
            <Row>
                <Col lg={9} ref={this.checkInRef}>
                  <BlockContent blocks={this.props._rawCopy} serializers={serializers} />
                </Col>
            </Row>
          </Container> 
        </Styles>
      </Section> :
      <Section>
        <Styles>
          <Container>
            <BlockContent blocks={this.props._rawCopy} serializers={serializers}/>
          </Container>
        </Styles>
      </Section>
      }
      </>
    );
  }
};

export default Copy;